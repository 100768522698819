import styled from "styled-components";
import { Link } from "react-router-dom";

interface ContainerProps {
  extendNavbar: boolean;
}

export const NavbarContainer = styled.nav<ContainerProps>`
  width: 100%;
  height: ${props => props.extendNavbar ? "100vh" : "80px"};
  background-color: ${p => p.theme.colors.trueWhite};
  display: flex;
  flex-direction: column;
  

  @media (min-width: 900px) {
    height: 80px;
  }
`;

export const NavbarInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  
  position: absolute;
  height: 84px;
  left: 0px;
  right: 0px;
  top: 0px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const NavbarLink = styled(Link)`
  color: ${p => p.theme.colors.purpleWhite};
  font-size: x-large;
  text-decoration: none;
  margin: 10px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled(Link)`
  color: ${p => p.theme.colors.purpleBlue};
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const LogoText = styled.h2`
  color: ${p => p.theme.colors.purpleBlue};
  display: flex;
  justify-content: center;
  font-weight: ${p => p.theme.fonts.bold};
  font-size: 32px;
`;

export const Logo = styled.img`
  margin: 10px;
  max-width: 180px;
  height: auto;
`;

export const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: ${p => p.theme.colors.purpleBlue};
  font-size: 45px;
  cursor: pointer;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavbarButtonLink = styled(Link)`
  align-items: center;
  border-radius: 4px;
  background: #256ce1;
  padding: 12px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  @media (max-width: 900px) {
    display: none;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
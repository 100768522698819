import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

interface PersonProps{
  photoUrl?: string;
}

export function Person({photoUrl}: PersonProps) {
  console.log(photoUrl);
  return (
    <StyledPersonItem>
      <StyledImage photoUrl={photoUrl} />
    </StyledPersonItem>
  )
}
const StyledPersonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60px;
  height: 60px;
`;

const StyledImage = styled.div<PersonProps>`
  width: 40px;
  height: 40px;
  background-image: url(${p => p.photoUrl});
  background-size: contain;
  border-radius: 200px;
`;
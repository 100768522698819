import React from "react";
import {NavLink , NavLinkProps } from "react-router-dom";
import styled from "styled-components";

export function ListItem({...props}: NavLinkProps) {

  return (
    <StyledListItem>
      <StyledLink {...props} />      
    </StyledListItem>
  )
}
const StyledListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 117px;
  height: 44px;
  flex: none;
  order: 0;
  flex-grow: 0;

  @media (max-width: 900px) {
    display: none;
  }
`;
//style={({isActive}) => {return {color: isActive ? 'skyblue': ''}}}
const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${p => p.theme.colors.grey_400};
  flex: none;
  order: 0;
  flex-grow: 0;
  &:active{
    color: ${p => p.theme.colors.purpleBlue};
  }
`
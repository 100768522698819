import  styled from 'styled-components';

export const StyledInput = styled.input`
  display: flex;
  flex: 1;
  padding: 8px 12px;
  background: ${p => p.theme.colors.trueWhite};
  color: ${p => p.theme.colors.grey_400};
  border: 1px solid ${p => p.theme.colors.grey_300};
  border-radius: 4px;
  outline: none;
`;

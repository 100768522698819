export default {
  colors: {
    primary: '#605DEC',
    secondary: '#605DEA',

    purpleBlue: '#605DEC',
    trueWhite: '#FFFFFF',
    green: '#77DD77',
    purpleWhite: '#F6F6FE',
    pinkGradient: '#EB568C',
    redGoogle: '#EA4335',

    grey_100: '#FAFAFA',
    grey_300: '#A1B0CC',
    grey_400: '#7C8DB0',
    grey_900: '#27273F',

    hovers: {
      primary: '#5250CC',      
      redGoogle: '#CC3C2F',
    }
  },
  fonts: {
    regular: 400,
    medium: 500,
    bold: 700,
  }
};
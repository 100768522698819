import { icons } from "@react-icons/all-files/lib";
import { ButtonHTMLAttributes } from "react"
import { StyledButton } from './styles'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: React.ReactNode;
  content?: string;
}

export function Button({content, children, icon, ...props}: ButtonProps){
  return (
    <StyledButton {...props}>
      {icon}
      {content ? content : children }
    </StyledButton>
  )
}
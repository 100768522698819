import { Routes } from "./Routes";
import { ThemeProvider } from 'styled-components';

import THEME from './Theme'
import { AuthContextProvider } from "./Contexts/AuthContexts";

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
      
    </ThemeProvider>
    
  );
}

export default App;

import React from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { FaGoogle } from "@react-icons/all-files/fa/FaGoogle";
import { 
  PageContainer, 
  Container, 
  LoginContainer, 
  WelcomeText,
  HorizontalRule,
  IconButton,
} from "./styles";
import { useAuth } from "../../Contexts/AuthContexts";

export function Login() {
  const { user, signIn } = useAuth();

  async function handleSignIn() {
    if (!user) {
      await signIn();
    }
  }

  return (
    <PageContainer>
      {/* <Aside /> */}
      <Container>
        <LoginContainer>
          <WelcomeText>GoHead</WelcomeText>
          <Input type="text" placeholder="User" />
          <Input type="password" placeholder="Password" />
          <Button content="Sign Up" />
          <HorizontalRule>or login with</HorizontalRule>
          <IconButton 
            onClick={handleSignIn} 
            icon={<FaGoogle />}>
            Google
          </IconButton>
        </LoginContainer>
         
        {/*  
          <IconsContainer>
          
          </IconsContainer> */}
      </Container>
    </PageContainer>
  );
}
import styled from "styled-components";
import background from '../../assets/login_background2.jpg'
import { Button } from "../../components/Button";

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  background: linear-gradient(0deg, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${background});
  background-size: cover;
  background-attachment: fixed;
`;

export const Aside = styled.aside`
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Container = styled.div`
    flex: 8;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LoginContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 280px;
    justify-content: center;
    gap: 8px;
`;

export const WelcomeText = styled.h2`
  color: ${p => p.theme.colors.trueWhite};
  display: flex;
  justify-content: center;
  font-weight: ${p => p.theme.fonts.medium};
  font-size: 32px;
`;

export const InputContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
justify-content: space-around;
align-items: center;
letter-spacing: 0.4rem;
margin-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
margin: 1rem 0 2rem 0;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

export const HorizontalRule = styled.div`
  display: flex;
  color: ${p => p.theme.colors.trueWhite};
  align-items: center;
  margin: 10px 0;
  &::before{
      content: '';
      flex: 1;
      height: 1px;
      background: #a8a8b3;
      margin-right: 16px;
    }
    &::after{
      content: '';
      flex: 1;
      height: 1px;
      background: #a8a8b3;
      margin-left: 16px;
    }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 80%;
`;

export const IconButton = styled(Button)`
  background: ${p => p.theme.colors.redGoogle};
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${p => p.theme.colors.hovers.redGoogle};
  }
`;
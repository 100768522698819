import { analytics } from "../../services/firebase";
import { logEvent } from "firebase/analytics";
import { useAuth } from "../../Contexts/AuthContexts";

logEvent(analytics, 'page_view', {page_title: 'Home'});

export function Home(){
  
  const { signOut } = useAuth();

  function handleSignOut(){
    signOut();

  }

  return (
    <div>
      <button onClick={handleSignOut}>
        Signout
      </button>
    </div>
  )
}
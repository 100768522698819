import React, { useState } from "react";

import { 
    NavbarContainer,
    NavbarExtendedContainer,
    NavbarInnerContainer,
    ListItemContainer,
    Logo,
    OpenLinksButton,
    NavbarLinkExtended,
    LogoText,
} from './styles'
import LogoImg from "../../assets/logo.png";
import { ListItem } from "./ListItem";
import { Person } from "./ListItem/Person";
import { useAuth } from "../../Contexts/AuthContexts";
import { User } from "../../Models/User";

interface NavbarProps{
  user?: User,
  textLogo?: string
}

export function Navbar({user, textLogo}: NavbarProps) {
  const [extendNavbar, setExtendNavbar] = useState(false);
  
  function handleLogo(): React.ReactElement{
    if(textLogo)
      return <LogoText>{textLogo}</LogoText> 
    
    return <LogoImg />
  }

  return (
      <NavbarContainer extendNavbar={extendNavbar}>
        <NavbarInnerContainer>
            {handleLogo()}
            <ListItemContainer>
              <ListItem to="/">Home</ListItem>
              <ListItem to="/solicitacao">Products</ListItem>
              <ListItem to="/acompanhamento">Contact Us</ListItem>
              {user ? <Person photoUrl={ user?.avatar }/> : ''}
              <OpenLinksButton
                onClick={() => {
                  setExtendNavbar((curr) => !curr);
                }}
              >
                {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
              </OpenLinksButton>
            </ListItemContainer>
        </NavbarInnerContainer>
        {extendNavbar && (
          <NavbarExtendedContainer>
            <NavbarLinkExtended to="/">Home</NavbarLinkExtended>
            <NavbarLinkExtended to="/products">Products</NavbarLinkExtended>
            <NavbarLinkExtended to="/contact">Contact Us</NavbarLinkExtended>
            <NavbarLinkExtended to="/sign-up">Sign Up</NavbarLinkExtended>
          </NavbarExtendedContainer>
        )}
      </NavbarContainer>
    );
}
import React, { ReactNode, useContext, useEffect } from 'react'
import { createContext, useState } from 'react'
import { auth, GoogleAuthProvider, signInWithPopup, signOut as fireSignOut } from '../services/firebase';

type AuthContextProviderProps = {
  children: ReactNode
}
type User = {
  id: string;
  name: string;
  avatar: string;
}

interface AuthContextData {
  signed: boolean;
  user: User | undefined;
  signIn: () => Promise<void>;
  signOut: () => void;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthContextProvider(props: AuthContextProviderProps) {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const { displayName, photoURL, uid } = user

        if (!displayName || !photoURL) {
          throw new Error('Missing information from Google Account.');
        }

        setUser({
          id: uid,
          name: displayName,
          avatar: photoURL
        })
      }
    })

    return () => {
      unsubscribe();
    }
  }, []);

  async function signIn() {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    console.log(result);
    

    if (result.user) {
      const { displayName, photoURL, uid } = result.user

      if (!displayName || !photoURL) {
        throw new Error('Missing information from Google Account.');
      }

      setUser({
        id: uid,
        name: displayName,
        avatar: photoURL
      })
    }

  }

  async function signOut() {
    fireSignOut(auth).then((result) => {
      console.log(result);
      setUser(undefined);
    }).catch((error) => {
      console.log(error);
    });
    
  }

  return (
    <AuthContext.Provider value={{ signed: !!user, user, signIn, signOut }} >
      { props.children }
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
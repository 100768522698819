import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.colors.purpleBlue};
  color: ${p => p.theme.colors.grey_100};
  padding: 8px 12px; 
  outline: none;
  border: none;
  border-radius: 4px;
  gap: 5px;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${p => p.theme.colors.hovers.primary};
  };
  &:focus {
    border: 1px solid ${p => p.theme.colors.trueWhite};
  };
`;


import { BrowserRouter, Route, Routes, createBrowserRouter } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { useAuth } from "../Contexts/AuthContexts";
import { Home } from "../Pages/Home";
import { Second } from "../Pages/Second";

interface RouterApp{
  path: string;
  label: string;
  element?: React.ReactNode;
  children?: RouterApp[];
}

export function AppRoutes() {
  const { user } = useAuth();

  const routes : RouterApp[] = [
    {
      path:"/",
      label: "",
      element: <Home />,
      children:[
        {
          path: "solicitacao",
          label: "Solicitações",
          element: <Second />
        }
      ]
    },
    {
      path: "solicitacao",
      label: "Solicitações",
      element: <Second />
    },
    {
      path: "acompanhamento",
      label: "Acompanhamento",
      element: <Second />
    },
    {
      path:"*",
      label: "",
      element: <Home />,
    }
  ];

  function handleRoute(route: RouterApp): React.ReactElement{
    if(route.children){
      route.children?.map(rt => handleRoute(rt));
    }
    
    console.log(route);
    return <Route key={route.path} path={route.path} element={route.element} />
  }

  return (
    <BrowserRouter>
      <Navbar user={user} textLogo="GoHead"/>
      <Routes>
        {
          routes.map((map: RouterApp) => handleRoute(map))

        }
      </Routes>
    </BrowserRouter>
  )
}